body {
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  margin: 0px;
  width: 100vw;
  padding: 0px;
  overflow-x: hidden;
  background-color: black;
}

html {
  height: fill-available;
  height: -webkit-fill-available;
}

.root {
  display: flex;
  align-items: flex-start;
}

.App {
  width: 100vw;
  height: 99vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;
  color: white;
}

.page-wrapper {
  width: 100vw;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  overflow-y: scroll;
  overflow-x: hidden;
  position: absolute;
  gap: 15vh;
  padding: 6vh 0 10vh 13vw;
}

.hide-overflow {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(255, 145, 0);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(231, 113, 17);
}

::-webkit-scrollbar-track {
  background-color: rgb(255, 255, 255);
}

a,
button {
  all: unset;
}

button:hover {
  cursor: pointer;
}

@media (max-width: 1100px) {
  .page-wrapper {
    padding: 6vh 0vh 2vh 0vh;
  }
}

@media (max-width: 400px) {
  body {
    height: 90vh;
  }
}
