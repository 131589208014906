.header {
  transform-origin: top;
  color: rgb(255, 255, 255);
  height: 45px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  border-bottom: .5px solid  rgb(255, 145, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0,0, 0.123);
  backdrop-filter: blur(3px)
}

.header-name-container {
  display: flex;
  width: 13rem;
  justify-content: space-between;
  align-items: center;
  margin-left: 3%;
}

.header-creative {
  color: rgb(255, 145, 0);
  font-size: 16px;
}

.header-name {
  font-size: 20px;
  letter-spacing: 5px;
}

.dropdown-button {
  z-index: 3;
  border: 1px solid white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: none;
  position: fixed;
  top: 14%;
  right: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chevron {
  color: white;
}

/*DROP DOWN*/

.dropdown {
  position: fixed;
  top: 0;
  right: 0;
  transform-origin: top;
  z-index: 1;
  height: 60vh;
  width: 15rem;
  background-color: rgb(255, 145, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
}

.dropdown-button:hover {
  cursor: pointer;
  border: solid 1px rgb(255, 145, 0);
}

.header-link-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  height: fit-content;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
}

.header-link:hover {
  cursor: pointer;
}

@media (max-width: 500px) {
  .header-name, .small {
    font-size: 16px;
  }

  .header-creative {
    font-size: 14px;
  }

  .header-name-container {
    width: 35%;
    min-width: 10rem;
  }

  .dropdown-button {
    top: 1vh;
    right: 3vw;
  }

  .dropdown {
    width: 45%;
  }
}