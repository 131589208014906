aside {
  height: 45vh;
  width: 11%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  top: 5%;
  padding: 20px;
  z-index: 3;
}

.link-wrapper {
  overflow: hidden;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.link-container {
  all: unset;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: space-evenly;
  justify-content: center;
  margin-bottom: 10px;
}

.link,
.header-link {
  all: unset;
  width: fit-content;
  font-size: 19px;
  font-family: "droid-serif", serif;
  color: rgb(255, 255, 255);
}

.name {
  text-align: center;
  margin: 0px;
  letter-spacing: 10px;
  font-family: "Bebas Neue", sans-serif;
}

.large {
  font-size: 42px;
}

.small {
  font-size: 20px;
}

.letter-wrapper {
  overflow: hidden;
}

.headshot {
  height: 60vh;
}

.creative {
  color: rgb(255, 145, 0);
  margin-top: 0px;
  font-size: 20px;
  letter-spacing: 4px;
  text-align: center;
}

.name-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex;
  font-family: "droid-serif", serif;
}

.link:hover,
.name-wrapper:hover {
  color: rgb(255, 145, 0);
  font-style: italic;
  cursor: pointer;
}

.name-container:hover {
  cursor: pointer;
}

.link:hover {
  border-bottom: 1px solid rgb(255, 145, 0);
}

.link:hover {
  letter-spacing: 1px;
}

@media (max-width: 1500px) {
  .link {
    font-size: 17px;
  }
}

@media (max-width: 1100px) {
  .large {
    font-size: 30px;
  }

  .name-container {
    width: 30%;
  }
}
