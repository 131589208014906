.app-link {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  letter-spacing: 1px;
  font-family: "droid-serif", serif;
  padding: 1px 8px;
  height: 40px;
  color: white;
  border: none;
  border: solid 0.5px rgb(255, 145, 0);
  background-color: black;
}

.app-link:hover {
  cursor: pointer;
  background-color: rgb(255, 145, 0);
  color: rgb(1, 17, 25);
}

.app-link:active {
  background-color: rgb(208, 104, 0);
}

@media (max-width: 1100px) {
  .app-link {
    font-size: 16px;
    max-height: 35px;
    padding: 5px 15px;
  }
}

@media (max-width: 500px) {
  .app-link {
    font-size: 14px;
    height: 25px;
    padding: 5px 10px;
  }
}
