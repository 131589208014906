.overlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0, 0.123);
}

.intro-wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.intro-text-wrapper {
  gap: 20px;
  position: absolute;
  left: 2%;
  bottom: 0;
  font-family: "droid-serif", serif;
  color: white;
  height: 100%;
  width: 95vw;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  overflow: hidden;
}

.intro-text {
  z-index: 2;
  font-size: 20px;
  width: fit-content;
}

.intro-img {
  position: absolute;
  top: 0;
  width: 100vw;
}

@media (min-aspect-ratio: 4352/3264) {
  .intro-img {
    width: 100vw;
    height: auto;
  }
}

@media (max-aspect-ratio: 4352/3264) {
  .intro-img {
    height: 100vh;
    width: auto;
  }
}

@media (max-width: 500px) {
  .intro-text {
    font-size: 15px;
  }

  .intro-text-wrapper {
    gap: 10px;
    bottom: 0;
    left: 3%;
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
  }
}
