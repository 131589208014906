.art-card-wrapper {
  display: flex;
  width: 100%;
}

.art-details-container {
  display: flex;
  align-items: flex-end;
}

.art-title,
.art-year {
  width: fit-content;
  margin: 5px 5px 0px 5px;
  font-size: 14px;
}

.art-title {
  color: white;
  font-family: "droid-serif", serif;
}

.art-year {
  color: rgb(255, 145, 0);
}

.art-item {
  height: 90vh;
  width: auto;
}

@media (max-width: 1440px) and (max-height: 700px) {
  .art-item {
    height: 90vh;
  }
}

@media (max-width: 1100px) {
  .art-card-wrapper {
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 7%;
    margin-bottom: 3%;
  }
}

@media (max-width: 700px) {
  .art-item {
    width: 67vw;
    height: auto;
  }
}
