.contact-wrapper {
  overflow: hidden;
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  background-color: black
}

.contact-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: rgb(255, 145, 0) .5px solid;
  padding: 60px;
  width: 30rem;
  height: 30rem;
}

.contact-text-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.contact-link-container {
  min-width: 30rem;
  width: 30rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 50px;
  overflow: hidden;
}

.contact-text {
  font-family: "droid-serif", serif;
  margin: 0px;
  font-size: 20px;
  text-align: center;
}

.contact {
  all: unset;
  top: 55%;
  text-align: auto;
  text-justify: auto;
  font-size: 36px;
  font-family: 'Bebas Neue', sans-serif;
}

@media (max-width: 1100px){
  .contact-text {
    font-size:16px;
  }

  .contact-container {
    width: 20rem;
    height: 20rem;
  }

  .email, .linkedin, .github {
    position: unset;
    font-size: 28px;
  }

  .contact-link-container {
    width: 100%;
    min-width: unset;
  }
}

@media (max-width: 500px) {
  
  .contact-container {
    width: 15rem;
    height: 15rem;
    padding: 2rem;
  }

  .contact-text {
    font-size: 13px;
  }

  .contact {
    font-size: 23px;
  }

  .contact-link-container {
    width: 100%;
    min-width: 5rem;
  }
}

