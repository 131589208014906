.about-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 16vh;
  width: 100vw;
}

.about-card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  height: 100vh;
  width: 70vw;
  padding: 0px 80px;
  font-family: "droid-serif", serif;
}

.about-text {
  width: 100%;
}

.cv-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;
}

.text-wrapper {
  border: 1px white solid;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.cv-wrapper {
  width: 100%;
  height: 80%;
}

.cv-title {
  border-bottom: 0.5px solid rgb(255, 145, 0);
  margin: 0px;
  margin-bottom: 10px;
  font-size: 40px;
  font-family: "Bebas Neue", sans-serif;
}

.cv-item-title {
  font-size: 20px;
  font-weight: bold;
}

.cv-item-container {
  font-family: "droid-serif", serif;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.cv-item-details {
  font-size: 14px;
}

.cv-item-wrapper {
  margin-bottom: 15px;
}

.about-text-container {
  height: 50%;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
}

.about-image {
  height: 70vh;
  margin-bottom: 3%;
}

.cv-year {
  width: fit-content;
  font-size: 20px;
  border-bottom: 0.5px solid rgb(255, 145, 0);
}

.cv-skill-container {
  margin: 0px;
  font-size: 30px;
  font-family: "Bebas Neue", sans-serif;
}

.create {
  font-size: 20px;
  padding: 20px;
  letter-spacing: 3px;
  font-family: "Bebas Neue", sans-serif;
  border: 0.5px solid rgb(255, 145, 0);
}

@media (max-width: 1100px) {
  .cv-skill-container {
    font-size: 20px;
  }

  .art-quote,
  .jacques,
  .create {
    font-size: 18px;
  }

  .about-wrapper {
    width: 90vw;
    margin: 0px;
    margin-left: 0px;
    align-items: flex-start;
  }

  .cv-wrapper {
    width: 90%;
  }

  .about-card-wrapper {
    padding: 0px;
    width: 100vw;
  }
}

@media (max-width: 700px) {
  .quote-wrapper {
    width: 80vw;
  }

  .art-quote {
    font-size: 15px;
  }

  .cv-item-title {
    font-size: 16px;
  }
}
