.programming-card-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100vw;
  height: fit-content;
  overflow: hidden;
  margin-top: 5vh;
}

.programming-image {
  width: 58vw;
}

.image-wrapper {
  width: fit-content;
}

.mobile-img {
  width: 20vw;
}

.mobile-img-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.app-text-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 23vw;
  min-width: fit-content;
  height: 100%;
  min-height: 420px;
  font-family: "droid-serif", serif;
  font-weight: 200;
  overflow: hidden;
}

.nectr {
  font-family: "Manrope", sans-serif;
  font-style: italic;
}

.HYPERLOOM {
  font-family: "Audiowide";
}

.wander {
  font-family: "Nanum Myeongjo", serif;
  font-style: italic;
}

.bordle {
  font-family: "Roboto", sans-serif;
}

h3,
.app-text {
  margin: 0px;
  margin-bottom: 5px;
}

h3 {
  font-family: "Bebas Neue", sans-serif;
  font-size: 32px;
  margin: 3px 0px;
  letter-spacing: 6px;
}

.app-name {
  text-align: center;
  font-size: 40px;
  margin: 0px;
}

.app-name-wrapper {
  overflow: hidden;
  border-bottom: 0.5px solid rgb(255, 145, 0);
  margin: 10px 0px;
  width: 90%;
}

.button-container {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-bottom: 20px;
}

.stack {
  width: 100%;
  gap: 20px;
  font-size: 24px;
  display: flex;
  justify-content: space-evenly;
}

.details-container {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 16px;
  width: 100%;
}

/* old*/
.mobile-mobile-img {
  margin-top: 5%;
  height: 60vh;
}

@media (max-width: 1700px) {
  .programming-image {
    width: auto;
    height: 67vh;
  }

  .app-name {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  .app-text {
    font-size: 14px;
  }

  .programming-card-wrapper {
    width: 90vw;
  }
}

@media (max-width: 1500px) {
  .abstract-paragraph {
    font-size: 14px;
  }
  .programming-image {
    width: 60vw;
    height: auto;
    min-height: none;
  }

  .mobile-img {
    width: 18vw;
  }

  .app-name {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  .app-text {
    font-size: 14px;
  }

  .programming-card-wrapper {
    width: 100vw;
    margin-left: 5%;
  }
}

@media (max-width: 1100px) {
  .button-container {
    width: 100%;
  }

  .programming-card-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .details-container {
    width: 17rem;
  }

  .app-text-container {
    width: 30%;
  }

  .app-text-wrapper {
    display: flex;
    align-items: flex-end;
    width: 65%;
    min-height: 0px;
    justify-content: space-evenly;
    align-items: center;
  }

  .side-text-wrapper {
    width: 45%;
  }

  .programming-image {
    margin: 5% 0% 1% 0%;
    width: 80vw;
    height: auto;
    min-height: none;
  }

  .button-container {
    margin: 0px;
  }
}

@media (max-width: 1100px) and (max-height: 700px) {
  .programming-image {
    margin: 0% 0% 1% 0%;
    width: 69vw;
    height: auto;
    min-height: 0px;
  }
}

@media (max-width: 700px) {
  .programming-image {
    width: 95vw;
    height: auto;
    margin: 20px, 0px;
  }

  .details-container {
    font-size: 24px;
  }

  .app-name-wrapper {
    width: 100%;
  }

  .app-name {
    font-size: 36px;
  }

  .programming-card-wrapper {
    width: 100vw;
    margin-left: 0px;
  }

  .side-text-wrapper {
    height: 20vh;
    width: 60%;
    justify-content: flex-end;
  }

  .mobile-abstract-modal {
    height: 80vh;
    width: 80vw;
  }
}

@media (max-width: 500px) {
  .programming-card-wrapper {
    height: 90vh;
  }

  .app-name {
    font-size: 24px;
  }

  .mobile-abstract-modal {
    height: 80vh;
    font-size: 16px;
  }
}

@media (max-width: 350px) {
  .mobile-mobile-img {
    height: auto;
    width: 90vw;
  }
}
